.dashboard{
    height:100vh;
    width: 100vw;
    text-align: center;
    color: rgb(71, 71, 219);

}

.dashboard-container{
    height:100%;
    width:100%;
    flex-direction: row;
}

.dashboard-sign-in-prompt{
    justify-content: space-between;
    justify-items: center;
    align-items: center; 
    align-content: center;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}

