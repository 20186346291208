.sign-in-prompt{
    position:fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

}
.sign-in-prompt-inner{
    position: relative;
    width: 100%;
    height:30%;
    background-color: #090a18;
    color: aliceblue;
    justify-content: space-between;
    justify-items: center;
    align-items: center; 
    align-content: center;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.sign-in-prompt-inner-mobile{
    position: relative;
    width: 100%;
    height:42%;
    background-color: #090a18;
    color: aliceblue;
    justify-content: space-between;
    justify-items: center;
    align-items: center; 
    align-content: center;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.sign-in-prompt-close-button-mobile{
    size: 200%;

}
