/* Google login button css */

.gsi-material-button {
    margin-top: 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: min(16px,2vw); /* Font size scales with viewport width */
    background-color: #fff;
    border: 1px solid #ccc;
    color: black;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, padding 0.3s ease-in-out;
    height: 3.5vh;/*min(10vh, 40x);*/
    width: min(15vw,170px);

  }
  
  .gsi-material-button:hover {
    transform: scale(1.05); /* Slightly increases size on hover */
  }
  

  .gsi-material-button-icon {
    height: 100%;
    flex: 1;
    margin-right: 1vw;
    width: 100%;
  }

   .gsi-material-button-contents {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    font-size: min(1.4vw, 14px);
    vertical-align: top;
    flex: 2;
      } 