/* Sidebar.css */

/* Base sidebar styling */
.sidebar {
    width: 3vw;
    background-color: #242424;
    height: 100vh; /* Full height of the viewport */
    position: absolute; /* Fixes the sidebar to the viewport */
    top: 0; /* Aligns the sidebar to the top */
    left: 0; /* Positions the sidebar to the left side */
    transition: width 0.3s ease; /* Smooth transition when opening/closing */
    overflow-x: hidden; /* Prevents horizontal scroll */
    display: flex;
    flex-direction: column;
    align-items: center;    
    font-size: min(1.5vw, 16px);
  }
  
  /* Expanded sidebar */
  .sidebar.open {
    width: 7vw;
    margin-right: 1%;
  }
  
  /* Toggle button styling */
  .toggle-btn {
    background-color: #3a3d4d;
    color: #fff;
    border: #fff;
    padding: 10px;
    margin: 20px 0;
    cursor: pointer;
  }
  
  /* Icon navigation styling */
  .icon-nav {
    margin-top: 50px;
  }
  
  .icon-link {
    display: block;
    margin: 20px 0;
    text-align: center;
    width: 100%;
  }
  
  .icon {
    width: 30px;
    height: 30px;
    filter: invert(100%); /* Inverts colors for a white icon on dark background */
  }
  
  /* Hide icons and text when the sidebar is collapsed */
  .sidebar .hidden {
    display: none;
  }
  
  /* Style for expanded sidebar showing icon text */
  .sidebar.open .icon-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: min(1.5vw, 16px);
    /* padding-left: 20px; */
  }
  
  .sidebar.open .icon {
    margin-right: 10px;
  }
  
  .sidebar.open .icon-link span {
    display: inline-block;
    color: #fff;
  }

  a:link {
    color: rgb(211, 208, 208); /* Set your desired link color here */
    text-decoration: none; /* Optional: Remove underline if needed */ 
  }

  a:visited {

    color: rgb(211, 208, 208); /* Set your desired link color here */
    text-decoration: none; /* Optional: Remove underline if needed */ 
  
  }
  
  .FA-icon{
    color: rgb(100, 102, 173);
    size: 40;
    padding-left: 1%;
  }
  