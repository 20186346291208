.SignUp-main{
    height:100%;
    width: 100%;
    flex-direction: column;
    position: relative;
    text-align: center;
    color: rgb(71, 71, 219);
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-top: 12%;
    display:flex;
    

}

.sign-up-container{
    /* text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center; */
    width: 30%;
    height:100%;
}

.input-section h3{
    color: rgb(101, 101, 212);
}

.input-section{
    width: 100%;
    /* display: flex;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    justify-items: flex-end;
    position: relative; */
}

.submit-button{
    height: 100%;
    width: 30%;
    margin-top: 10%;
}