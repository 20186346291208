.About-main {
    display: flex;
    height: 100%;
    background-color: #0f0f0f;
    
    flex-direction: column;
    padding: 0;
    width: 100%;
    justify-content: center;
    margin-left: 5%;
    margin-top: 1%;
    color: rgb(71, 71, 219);
}

.written-text{
    flex: 1;
    width: 60%;
    justify-content: center;
    color: rgb(219, 213, 179);
}

.jotform{
    justify-content: center;
    flex:2;
    height: 100%;
    width: 70%;
    
}