/* App.css */
.App {
  display: 1;
  height: 100vh;
  width: 100vw;
  /* background-color: #0f0f0f; */
  margin: 0;
  padding: 0;
  
}
.header {
  background-color: #090a18;
  border-color: rgb(71, 71, 219);
  color: rgb(71, 71, 219);
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  width: 100%;
  height: 7%;
  position: relative; /* Fixes the header to the top */
  z-index: 1; /* Ensures the header is above other content */
  flex-direction: row;
  text-align: center;
  justify-content: space-between; /* Or space-around, space-evenly */
  align-items: center; /* Vertically center items */
  flex-wrap: nowrap;
  overflow-y:hidden;
   
}

.header h1 {
  margin: 0;
  font-size: min(3vh, 4vw, 48px);
  color:rgb(71, 71, 219);
  
}

.title-and-login {
  display: flex; 
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
  margin-right: 5%;
  margin-left: 5%;
  height:40%;
  margin-top: 0.5%;
}

.signed-in-user{
  display:flex;
  flex-direction: row;
  font-size: min(2vh, 2vw, 16px);
  margin-top: 1vw;
}

.header-links{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50%;
  
}
/*  */
.header-links-sub{
  display: flex;
  margin-left: 8%;
  margin-right: 8%;
  text-wrap:nowrap;
  font-size: min(1.5vh, 2.25vw, 16px);
  color: rgb(101, 101, 212);
}


html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #0f0f0f;
  /* background-image: linear-gradient(#0f0f0f, rgb(19, 19, 100)); */
  font-family: sans-serif;
}
.sidebar {
  z-index: 1;
  margin-top: 0.2%;
  margin-left: 0.2%;
  border-radius: 8px;
  outline-width: 1px;
  outline-color: rgb(126, 125, 125);
  outline-style: solid;
}

.main-content {
  display: flex;
  justify-content: space-between; /* Space between stock scores and graph */
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  width: 95%;
  align-items: flex-start; /* Ensure that both components start from the top */
  height: 100%;
  margin-bottom: 2%;
  position: relative;
}
.main-content-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  width: 95%;
  align-items: center; 
  height: 100%;
  margin-bottom: 2%;
  position: relative;
}

/* StockScores */
.stockscores-container {
  flex: 1; /* Adjusts the width of StockScores, 3 parts of total width */
  /* margin-right: 1%; Add spacing between StockScores and StockGraph */
  margin-left: 0%;
  /* flex-direction: row; Ensure the table and graph stay side by side */
  width: 90%;
  height: 90%;
  overflow-y: hidden;
  position: relative;
  display:flex;
  flex-direction: column;
}
.stockscores-container-mobile {
  flex: 1; /* Adjusts the width of StockScores, 3 parts of total width */
  margin-right: 1%; /* Add spacing between StockScores and StockGraph */
  margin-left: 1%;
  /* flex-direction: row; Ensure the table and graph stay side by side */
  width: 100%;
  height: 90%;
  overflow-y: hidden;
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: flex-start; 
  justify-content: flex-start; 
  align-content: flex-start;
  justify-items: flex-start;
  margin-top: 1.5%;
}

/* StockGraph */
.graph-container {
  display: flex; /*added in with removal of historical */
  /* flex: 1; Commented out with removal of historical */
  height:30%; /*changed from 100 */
  width:95%;
  flex-direction: column;
  /* margin-left: 1%; */
  overflow-y: hidden;
}
.graph-container-mobile {
  /* flex: 1; Adjusts the width of StockGraph, 2 parts of total width */
  display:flex;
  height:30%; 
  width:100%;
  flex-direction: column;
  /* margin-left: 1%; */
  /* flex-shrink: 10; */
}

.tradingview-widget-container {
  flex:1;
  height: 70%; 
  width: 100%; /* Make sure the graph container takes full width */
  position: relative;
}
.tradingview-widget-container-mobile {
  flex:1;
  height: 70%; 
  width: 100%; /* Make sure the graph container takes full width */
  position: relative;
}
.historical-table-container {
  flex:1;
  width: 100%; 
  position: relative;
  height:100%;
  /* overflow-y: scroll; */
}
.historical-table-container-mobile {
  flex:1;
  width: 100%; 
  position: relative;
  height:40%;
  overflow-y: scroll;
}

.historical-table-container table {
  width: 100%; /* Make the table fill the full width of the container */
  border-collapse: collapse; /* Ensure borders are collapsed for cleaner appearance */
  table-layout: fixed;
  margin-top: 2%;
  /* overflow-y: scroll; */
}

.historical-table-container th,
.historical-table-container td {
  text-align: center; /* Align text to the left for better readability */
  border-bottom: 1px solid #3a3939;
  border-left: 0px;
  text-overflow: ellipsis;
  font-size: min(2vw, 16px);
  word-break: keep-all; 
  white-space: normal; /* Allows the text to wrap */
 
  
}

.historical-table-container th {
  background-color: #333; /* Set a background color for table headers */
  color: rgb(199, 199, 199); /* White text for headers */
}

.historical-table-container td {
  background-color: #242424; /* Dark background for table rows */
}

/* .historical-table-container tr:nth-child(even) {
  background-color: #5c3d3d; 
} */

.disclaimer {
  background-color: #2c2c2c;
  color: bisque;
  padding: 20px;
  text-align: center; /* Center align text */
  font-size: min(2vw, 16px); /* Adjust font size */
  width: 100%;
  margin:0;
  z-index: 2;
}
a{
  color: blue;
}

.welcome-statement-container{
  margin: 5%;
}

.welcome-statement-header{
  text-align: center;
  color:rgb(71, 71, 219);
}
.welcome-statement-paragraph{
  font-size: min(3vw, 16px);
  text-align: center;
  color: #ddd;
  text-align: left;
}

button{
  background-color: rgb(71, 71, 219);
  /* border: solid; */
  border-width: 0px;
  border-color: rgb(71, 71, 219);
  color: white;
  font-size: min(2vw, 16px);
  width: min(8vw,150px);
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  
}

.popup-overlay{
  text-align: center;
  align-items: center;
  align-self: center;
  display: flex;
  position: relative;
}
.popup-content{
  color: white;
}

.filter-button{
  color: white;
  background-color: rgb(71, 71, 219);
  margin: 10%;
  align-content: center;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center; /* Center text inside button */
  text-align: center;
}
.filter-button-in-popup{
  margin: 1%;
  display: inline-flex;
  justify-items: center;
  align-self: center;
  width: 100%;
  text-align: center;
  /* margin-left: 25%;
  margin-right: 25%; */
}


.remove-button{
  background-color: red;
  margin-left: 1%;
}

/* .cancel-button{
  background-color: rgb(102, 5, 5);
  margin-left: 1%;
} */

.filter-option-dropdowns{
  text-align: center;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  margin: 1%;
  justify-content: center;
  position: relative;
  align-content: center;
  align-self: center;
  
}

