.stock-score-positive {
    color: rgb(98, 209, 98);
  }
  
  .stock-score-negative {
    color: rgb(221, 70, 70);
  }
  .stock-score-neutral {
    color: rgb(204, 207, 206);
  }

  .percent-change-positive {
    color: rgb(98, 209, 98);
  }
  .percent-change-negative {
    color: rgb(221, 70, 70);
  }

  .percent-change-neutral {
    color: rgb(204, 207, 206);
  }
  


  .historical-table-container{
    position: relative;
    /* overflow-y: hidden; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    /* max-width: 20vw; */
  }