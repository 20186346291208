/* Container for the stock scores and graph */
.stock-scores-container {
    display: flex;
    justify-content: space-between; 
    /* padding: 20px; */
    /* border-radius: 15px; */
    background-color: #242424;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: auto;
    position: absolute;
    flex-direction: column; /* Allow header and content to stack vertically */
    outline-width: 1px;
    outline-color: rgb(126, 125, 125);
    outline-style: solid;
    margin-right: 1%;
    align-items: flex-start;
    align-self: flex-start;
    height: auto;
  }
  
  /* Header section for the title and search bar */
  .stock-scores-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure the title is centered and the search bar is on the right */
    margin-bottom: 1%;
    margin-top: 1%;
    width: 100%; /* Ensure the header takes full width */
    color: antiquewhite;
    flex-direction: column;
    align-self: flex-start;
  }
  
  /* Center the title */
  .stock-scores-header h2 {
    flex-grow: 1;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
 
  .stock-scores-table-container {
    width: 100%;
    overflow-y: auto;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    height: auto;
    /* align-content: space-between; */
  }
  
  .stock-scores-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    border-collapse: collapse;
    justify-content:flex-start; 
    align-items: flex-start;
    align-content:flex-start;
    /* display: flex; */
  }
 
  .stock-scores-table-container td {
    padding: 12px;
    border-bottom: 1px solid #262525;
    text-align: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    cursor: pointer;
    font-size: min(2.5vw, 16px);
  }

  .stock-scores-normal-color{
    color:rgb(204, 207, 206);
  }
  
  .stock-scores-table-container th {
    background-color: #140e0e;
    padding: 12px;
    border-bottom: 1px solid #262525;
    text-align: center;
    color:rgb(204, 207, 206);
    word-wrap: normal;
    font-size: min(2vw, 16px);
  }
  
  .stock-score-positive,.stock-score-negative,.stock-score-neutral{
    border-left: rgba(80, 79, 79, 0.623);
    border-left-style: solid;
    border-width: 1px;
  }
  
  .stock-score-positive {
    color: rgb(98, 209, 98);
    font-weight: 600;
  }
  
  .stock-score-negative {
    color: rgb(221, 70, 70);
    font-weight: 600;
  }
  .stock-score-neutral {
    color: rgb(204, 207, 206);
  }

  .percent-change-positive {
    color: rgb(98, 209, 98);
  }
  .percent-change-negative {
    color: rgb(221, 70, 70);
  }

  .percent-change-neutral {
    color: rgb(204, 207, 206);
  }

  .suggestion-box th{
    color: rgba(189, 186, 25, 0.623);
  }
  .ticker-button{
    width: fit-content;
    border-color: rgb(190, 179, 179);
    border-width: 1px;
    text-align: center;
    align-self: center;
    justify-self: center;
    /* text-align: center; */
  }
  
  .info-button{
    justify-content: flex-end;
    justify-items: right;
    align-items: flex-end;
    align-content: flex-end;
    display: flex;
    flex-direction: row;
    position:static ;
  }

  .stockscores-loading-symbol{
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-items: center;
  }