.stockscore-info-prompt{
    position:fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: left;
    align-items: left;
    z-index: 5;

}
.stockscore-info-prompt-mobile{
    position:fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: left;
    align-items: left;
    z-index: 5;

}
.stockscore-info-prompt-inner{
    position: relative;
    width: 100%;
    height:40%;
    background-color: #090a18;
    color: aliceblue;
    justify-content: space-between;
    justify-items: center;
    align-items: center; 
    align-content: center;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    color:rgb(71, 71, 219);
    
}
.stockscore-info-prompt-inner-mobile{
    position: relative;
    width: 100%;
    height:75%;
    background-color: #090a18;
    color: aliceblue;
    justify-content: space-between;
    justify-items: center;
    align-items: center; 
    align-content: center;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    color:rgb(71, 71, 219);
    font-size: 3vw;
    
}
.stockscore-info-prompt-inner-mobile button{
    width: 15vw;
    font-size: 5vw;
    margin-bottom: 10%;

}
/* .stockscore-info-prompt-inner-mobile br{
    display: block;
    margin: 1% 1%;
 } */
.field-info{
    margin-left: 2.5%;
    margin-right: 2.5%;
    flex-direction: row;
    display: flex;
}



.field-info h3{
    color: rgb(101, 101, 212);
    
}


.field-info p{
    color:aliceblue;
    
}